// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../assets/images/img_one.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, "\n.container[data-v-78876a50]{\r\n  width: 100vw;\r\n  height: 100vh;\n}\n.function-part[data-v-78876a50]{\r\n  border:1px dotted #aaa;\r\n  display: flex;\r\n  margin: 10vw 0 0 10vw;\r\n  gap: 10px;\n}\n.login-container[data-v-78876a50] {\r\n  width: 100%;\r\n  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);\r\n  border-radius: 4px;\r\n  background: rgb(60, 74, 169);\r\n  margin-top: var(--topbarHeight);\n}\n#login-container[data-v-78876a50]{\r\n  z-index: 1;\n}\n.login-container li[data-v-78876a50] {\r\n  /*line-height: 60px;*/\r\n  display: inline-block;\r\n  font-size: 16px;\r\n  color: #333;\r\n  margin: 0 5px;\n}\n.headPage[data-v-78876a50] {\r\n  width: 80%;\r\n  padding: 0 10%;\r\n  display: flex;\r\n  align-items: center;\r\n  height: 41.7vw;\r\n  min-height: 500px;\r\n  min-width: 800px;\r\n  position: relative;\n}\n.title-background-shading[data-v-78876a50] {\r\n  display: flex;\r\n  justify-content: space-between;\r\n  position: absolute;\r\n  overflow: hidden;\r\n  left: 0;\r\n\r\n  width: 100%;\r\n  height: 5.2vw;\n}\n.shading[data-v-78876a50] {\r\n\r\n  width: 24vw;\r\n  height: 24vw;\r\n  background-color: #4451ab;\n}\n.shading-1[data-v-78876a50] {\r\n  align-items: flex-end;\r\n  top: 0;\n}\n.shading-1 .shading-rect-1[data-v-78876a50] {\r\n  transform: translateY(-2.6vw);\n}\n.shading-round[data-v-78876a50] {\r\n  border-radius: 12vw;\n}\n.title-background[data-v-78876a50] {\r\n  position: relative;\r\n  width: 46.875vw;\r\n  min-width: calc(0.46875 * var(--minBaseWidth));\r\n  height: 25.26vw;\r\n  min-height: calc(0.2526 * var(--minBaseWidth));\r\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\r\n  background-size: contain;\r\n  background-repeat: no-repeat;\n}\n.shading-2[data-v-78876a50]{\r\nbottom:0\n}\n.chart[data-v-78876a50] {\r\n  height: 400px;\n}\r\n", ""]);
// Exports
module.exports = exports;
