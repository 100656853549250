<template>
  <div style="width: 500px">
  <v-chart class="chart" :option="option" />
  </div>
  <div class="container">

    <button @click="test404">点击测试404</button>
    <!--    <div class="function-part">-->
    <!--      <SignupComponent source="xxx"></SignupComponent>-->
    <!--    </div>-->

    <!--    <div class="function-part">-->
    <!--      <JoinTeam></JoinTeam>-->
    <!--    </div>-->

    <div class="function-part">
      <AuthorizeUserComponent :mission-type="'joinTeam'" :show-close-btn="true">
        <!--        <template #tip><p style="margin: 0;text-align: center">注册后，免费下载该文档</p></template>-->
      </AuthorizeUserComponent>
      <AuthorizeUserComponent :mission-type="'completeUserInfo'" :show-close-btn="true">
        <!--        <template #tip><p style="margin: 0;text-align: center">注册后，免费下载该文档</p></template>-->
      </AuthorizeUserComponent>
      <AuthorizeUserComponent :mission-type="'login'" :show-close-btn="true">
        <template #tip><p style="margin: 0;text-align: center">注册后，免费下载该文档</p></template>
      </AuthorizeUserComponent>
    </div>

  <div class="login-container">
    <div class="headPage">
      <div class="title-background-shading shading-1">
        <div class="shading shading-round"></div>
        <div class="shading shading-rect shading-rect-1"></div>
        <div class="shading shading-round"></div>
        <div class="shading shading-rect shading-rect-2"></div>
      </div>
      <div class="title-background-shading shading-2">
        <div class="shading shading-rect shading-rect-1"></div>
        <div class="shading shading-round"></div>
        <div class="shading shading-rect shading-rect-2"></div>
        <div class="shading shading-round"></div>
      </div>
      <div class="title-background">
      </div>
      <div id="login-container">
        <AuthorizeUserComponent :mission-type="'completeUserInfo'">

        </AuthorizeUserComponent>
      </div>
    </div>
    <complete-user-name></complete-user-name>
    <create-team></create-team>
   </div>
  </div>
</template>

<script>
import SignupComponent from "./SignupComponent";
import JoinTeam from "../../views/JoinTeam";
import AuthorizeUserComponent from "./AuthorizeUserComponent";
import CompleteUserName from "../CompleteUserName";
import CreateTeam from "../../views/CreateTeam";

import { use } from "echarts/core";
import { CanvasRenderer } from "echarts/renderers";
import { PieChart } from "echarts/charts";
import {
  TitleComponent,
  TooltipComponent,
  LegendComponent
} from "echarts/components";
import VChart, { THEME_KEY } from "vue-echarts";
import { ref, defineComponent } from "vue";

use([
  CanvasRenderer,
  PieChart,
  TitleComponent,
  TooltipComponent,
  LegendComponent
]);

export default defineComponent({
  name: "HelloWorld",
  components: {
    VChart,
    CreateTeam, CompleteUserName, AuthorizeUserComponent, JoinTeam, SignupComponent,
  },
  methods:{
    test404(){
      let url = 'http://www.baidu.com';
      if(url.startsWith('http')){
        window.location.href= url;
        return;
      }
      this.$router.push(url);
    }
  },
  provide: {
    [THEME_KEY]: "dark"
  },
  setup () {
    const option = ref({
      title: {
        text: "Traffic Sources",
        left: "center"
      },
      tooltip: {
        trigger: "item",
        formatter: "{a} <br/>{b} : {c} ({d}%)"
      },
      legend: {
        orient: "vertical",
        left: "left",
        data: ["Direct", "Email", "Ad Networks", "Video Ads", "Search Engines"]
      },
      series: [
        {
          name: "Traffic Sources",
          type: "pie",
          radius: "55%",
          center: ["50%", "60%"],
          data: [
            { value: 335, name: "Direct" },
            { value: 310, name: "Email" },
            { value: 234, name: "Ad Networks" },
            { value: 135, name: "Video Ads" },
            { value: 1548, name: "Search Engines" }
          ],
          emphasis: {
            itemStyle: {
              shadowBlur: 10,
              shadowOffsetX: 0,
              shadowColor: "rgba(0, 0, 0, 0.5)"
            }
          }
        }
      ]
    });

    return { option };
  }
});
</script>

<style scoped>
.container{
  width: 100vw;
  height: 100vh;
}
.function-part{
  border:1px dotted #aaa;
  display: flex;
  margin: 10vw 0 0 10vw;
  gap: 10px;
}

.login-container {
  width: 100%;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
  border-radius: 4px;
  background: rgb(60, 74, 169);
  margin-top: var(--topbarHeight);

}
#login-container{
  z-index: 1;
}

.login-container li {
  /*line-height: 60px;*/
  display: inline-block;
  font-size: 16px;
  color: #333;
  margin: 0 5px;
}

.headPage {
  width: 80%;
  padding: 0 10%;
  display: flex;
  align-items: center;
  height: 41.7vw;
  min-height: 500px;
  min-width: 800px;
  position: relative;
}

.title-background-shading {
  display: flex;
  justify-content: space-between;
  position: absolute;
  overflow: hidden;
  left: 0;

  width: 100%;
  height: 5.2vw;
}
.shading {

  width: 24vw;
  height: 24vw;
  background-color: #4451ab;

}
.shading-1 {
  align-items: flex-end;
  top: 0;
}

.shading-1 .shading-rect-1 {
  transform: translateY(-2.6vw);
}

.shading-round {
  border-radius: 12vw;
}
.title-background {
  position: relative;
  width: 46.875vw;
  min-width: calc(0.46875 * var(--minBaseWidth));
  height: 25.26vw;
  min-height: calc(0.2526 * var(--minBaseWidth));
  background-image: url(../../assets/images/img_one.png);
  background-size: contain;
  background-repeat: no-repeat;
}
.shading-2{
bottom:0
}
.chart {
  height: 400px;
}
</style>
