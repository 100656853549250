<template>
  <div class="login-container__local" v-show="!registDialogVisible">
    <div class="header-tab">
      <div class="login-tab-wrap" v-show="footerTabIndex === 1">
        <p>账号密码登录</p>
      </div>
      <ul class="login-tab-wrap" v-show="footerTabIndex === 0">
        <li :class="headerTabIndex === 0 ? 'tabIsActive' : ''" @click="headerTabChange(0)">微信扫码登录</li>
        <li :class="headerTabIndex === 1 ? 'tabIsActive' : ''" @click="headerTabChange(1)">手机验证登录</li>
      </ul>
    </div>
    <div class="content-tab">
      <div class="login-body" v-show="headerTabIndex === 0 && footerTabIndex === 0"> <!-- 微信扫码登录-->
        <div v-show="wxloginFailed" class="wxlogin-failed-wrap">
          <span>微信二维码暂时无法加载</span>
          <span>请使用其他登录方式或刷新页面</span>
        </div>
        <div v-show="!wxloginFailed" id="wechatcode"></div>
      </div>
      <div v-show="headerTabIndex === 1 && footerTabIndex === 0"> <!--手机验证码登录-->
        <el-form :model="registerDate" label-width="0px" class="login-content-form" :rules="phoneLoginRules"  ref="phoneLoginRef">
          <el-form-item prop="phone">
            <el-input placeholder="输入手机号码" v-model="registerDate.phone" class="register-phone-wrap__local">
              <template v-slot:prepend>
                <el-input v-model="registerDate.country_code" placeholder="86" class="register-codeinput"/>
              </template>
            </el-input>
          </el-form-item>
          <el-form-item prop="phonecode">
            <el-input placeholder="输入验证码" v-model="registerDate.phonecode" class="phonecode-input-wrap">
              <template v-slot:append>
                <el-button class="phonecode-btn" :disabled="codeButton.isDisabled" @click="getPhoneCodeFunc('login')">{{ codeButton.name }}</el-button>
              </template>
            </el-input>
          </el-form-item>
          <el-form-item prop="invite_code" v-show="!isRegisteredPhone">
            <el-input placeholder="输入邀请码" v-model="registerDate.invite_code" class="phonecode-input-wrap"/>
          </el-form-item>
        </el-form>
        <div class="login-btn-wrap">
          <el-button v-if="isRegisteredPhone" type="primary" class="login-button" @click="loginSubmit">登录/注册</el-button>
          <el-button v-else type="primary" class="login-button" @click="registerSubmit('phoneregist')" :disabled="isLoginRequestNow">登录/注册</el-button>
          <p class="userpolicy-pbtn">点击登录/注册，表示已同意
            <a href="/privacypolicy.html" data-tag-id="userpolicy" target="_blank">隐私协议</a> 和
            <a href="/servicepolicy.html"  data-tag-id="servicepolicy" target="_blank">写作猫产品服务协议</a>
          </p>
        </div>
      </div>
      <div v-show="footerTabIndex === 1"> <!--账号密码登录-->
        <el-form :model="registerDate" label-width="0px" class="login-content-form" :rules="emailLoginRules" ref="emailLoginRef">
          <el-form-item prop="email">
            <el-input placeholder="输入邮箱/手机号" v-model="registerDate.email"></el-input>
          </el-form-item>
          <el-form-item class="password-wrap" prop="password">
            <el-input placeholder="输入登录密码" v-model="registerDate.password" show-password class="phonecode-input-wrap"></el-input>
          </el-form-item>
        </el-form>
        <div class="login-btn-wrap" style="padding: 0px 0 10px;">
          <el-button type="primary" class="login-button" @click="loginSubmit">登 录</el-button>
        </div>
      </div>
    </div>

    <div class="register-tip">注册后，免费下载该文档</div>

    <span>
         <p @click="footerTabChange(1)" v-if="footerTabIndex === 0">账号密码登录</p>
         <p @click="footerTabChange(0)" v-else>微信扫码登录</p>
      </span>
  </div>

  <!--注册界面-->
  <el-dialog title="请完善个人信息，完成注册" v-model="registDialogVisible" width="50%" custom-class="regist-wrap"  :close-on-click-modal="false" :close-on-press-escape="false" :show-close="false">
    <el-form :model="userInfor" :label-position="labelPosition" :rules="userRuleForm" ref="userInfor" label-width="120px" class="registerpanel" status-icon size="small">
      <el-form-item label="国际区号">
        <el-input v-model="userInfor.country_code" placeholder="86"></el-input>
      </el-form-item>
      <el-form-item label="手机号码" prop="phone">
        <el-input v-model="userInfor.phone" placeholder="手机号码"></el-input>
      </el-form-item>
      <el-form-item label="手机验证码" prop="verification">
        <el-col :span="14"><el-input v-model="userInfor.verification"></el-input></el-col>
        <el-col :span="10" style="text-align: right;"><el-button :disabled="codeButton.isDisabled" @click="getPhoneCodeFunc">{{ codeButton.name }}</el-button></el-col>
      </el-form-item>
      <el-form-item label="邀请码" v-if="validCodeControl" prop="invite_code">
        <el-input v-if="userInfor.invite_code"   v-model="userInfor.invite_code"  disabled></el-input>
        <el-input v-else v-model="userInfor.invite_code"></el-input>
      </el-form-item>
    </el-form>

    <template v-slot:footer>
      <el-col class="registdialog-footer">
        <p>注册表示阅读并同意
          <a href="/privacypolicy.html"  data-tag-id="userpolicy" target="_blank">隐私协议</a>
        </p>
        <el-button type="primary" @click="registerSubmit('wxregist')" class="btn-all" :disabled="isLoginRequestNow">立即加入</el-button>
      </el-col>
    </template>

  </el-dialog>
</template>

<script>
import {
  checkValidCode,
  loginByPhone,
  checkPhoneCode,
  getPhoneCode,
  loginUranus,
  requestLogin,
  loginByAccount,
  getValid,
} from '../../api/api';
import {uuidGen} from '../../util';
import config from '../../config';
import { JSEncrypt } from 'jsencrypt'

export default {
  name: 'LoginComponent',
  props: {
    inviteCode: {
      type: String,
      required: false,
    },
    source: {
      type: String,
      requires: true
    }
  },
  emits: ['loginSuccess', 'signupSuccess'],
  data() {
    let checkPhoneNum = (rule, value, callback) => {
      if (!value) {
        callback(new Error('手机号不能为空'))
      } else if (isNaN(value)) {
        callback(new Error('请输入0-9的数字'))
      } else if (value.length < 8 || value.length > 14) {
        callback(new Error('请输入8-14位有效手机号码'))
      } else {
        callback()
      }
    }

    let checkVerification = (rule, value, callback) => {
      if (!value) {
        this.checkCodeOk = false
        callback(new Error('验证码不能为空'))
      } else if (isNaN(value)) {
        this.checkCodeOk = false
        callback(new Error('请输入0-9的数字'))
      } else {
        this.phoneCodeCheckFunc(callback)
      }
    }

    let invitedCodeValidator = (rule, value, callback) => {
      if (!value) {
        callback(new Error('邀请码不能为空'))
      } else {
        this.checkInvitedCode(callback)
      }
    };

    let checkEmail = (rule, value, callback) => {
      if (!value) {
        callback()
      } else {
        this.checkEmailFunc(callback)
      }
    }

    /**
     * 邮箱和手机号码登录
     * @param rule
     * @param value
     * @param callback
     */
    let checkLooginByemailOrPhone = (rule, value, callback) => {
      let reg = /(^[\w.\-]+@(?:[a-z0-9]+(?:-[a-z0-9]+)*\.)+[a-z]{2,3}$)|(^1[3|4|5|7|8|9]\d{9}$)/i
      if (!value) {
        callback(new Error('邮箱或者手机号不能为空'))
      } else if (!reg.test(value)) {
        callback(new Error('请检查账号格式'))
      } else {
        callback()
      }
    }

    return {
      /**
       * 验证码计时器
       */
      timer: null,
      /**
       *
       */
      wxloginIsTrue: true,
      /**
       *
       */
      emailLoginIsTrue: false,
      /**
       * 控制显示
       */
      headerTabIndex: 0,
      /**
       * 控制显示
       */
      footerTabIndex: 0,
      /**
       * 加载微信登录图片失败之后显示失败信息
       */
      wxloginFailed: false,
      /**
       * 电话号码是否注册过，默认认为注册过
       * 再获取验证码时 确定这个电话号码是否注册过
       * - 注册过走登录流程
       * - 未注册走注册流程
       */
      isRegisteredPhone: true,
      registerDate: {
        country_code: '86',
        phone: '',
        phonecode: '',
        email: '',
        password: '',
        invite_code: 'MGO-VOL7322C69B'
      },
      userInfor: {
        country_code: '86',
        invite_code: 'MGO-VOL7322C69B'
      },
      codeButton: {
        isDisabled: false,
        name: '发送验证码',
        time: 60
      },
      phoneLoginRules: {
        phone: [
          { required: true, validator: (rule, value, callback) => {
              if (!value) {
                callback(new Error('手机号不能为空'))
              } else if (isNaN(value)) {
                callback(new Error('请输入0-9的数字'))
              } else if (value.length < 8 || value.length > 14) {
                callback(new Error('请输入8-14位有效手机号码'))
              } else {
                callback()
              }
            }, trigger: 'blur' }
        ],
        phonecode: [
          { required: true, validator: (rule, value, callback) => {
              if (!value) {
                callback(new Error('验证码不能为空'))
              } else if (isNaN(value)) {
                callback(new Error('请输入0-9的数字'))
              } else if (value.length !== 4) {
                callback(new Error('请输入4为有效数字验证码'))
              } else {
                callback()
              }
            }, trigger: 'blur' }
        ],
        invite_code: [
          { required: true, validator: invitedCodeValidator, trigger: ['blur', 'change'] }
        ]
      },
      emailLoginRules: {
        email: [
          { required: true, validator: checkLooginByemailOrPhone, trigger: 'blur' }
        ],
        password: [
          { required: true, message: '密码不能为空', trigger: 'blur' }
        ]
      },
      /**
       * 是否显示注册弹窗
       **/
      registDialogVisible: false,
      labelPosition: 'left',
      checkCodeOk: false,
      isLoginRequestNow: false,
      validCodeControl: false,
      userRuleForm: {
        phone: [
          { required: true, validator: checkPhoneNum, trigger: 'blur' }
        ],
        verification: [
          { required: true, validator: checkVerification, trigger: 'blur' }
        ],
        invite_code: [
          { required: true, validator: invitedCodeValidator, trigger: ['blur', 'change'] }
        ],
        email: [
          { type: 'email', message: '请输入有效的邮箱', trigger: 'blur' },
          { validator: checkEmail, trigger: 'blur' }
        ]
      },
    }
  },
  created() {
    if (this.inviteCode) {
      this.userInfor.invite_code = this.inviteCode;
      this.registerDate.invite_code = this.inviteCode;
    }
  },
  mounted() {
    this.showLoginQRCode();
  },
  methods: {
    /**
     * 显示微信登录二维码
     */
    showLoginQRCode: function () {
      const uuid = uuidGen();

      let url = `${config.baseUrl}wx_metago_public_qr/metago?meta_token=${uuid}`;
      const wechatContainer = document.getElementById("wechatcode");
      const qrImg = document.createElement("IMG");
      qrImg.style = "width: 100%; heigh: 100%";
      qrImg.src = url;
      qrImg.onerror = () => {
        this.wxloginFailed = true;
      };
      qrImg.onload = () => {
        this.loginByQRCode(uuid);
      };
      wechatContainer.appendChild(qrImg)
    },
    loginByQRCode: function (uuid) {
      let login = function() {
        let params = {
          login_by: 'metago_scan',
          meta_token: uuid,
        };

        return loginUranus(params);
      };

      /**
       * fun 要求是promise
       */
      let repeat =(fun, interval) => {
        setTimeout(() => {
          fun().then(res => {
            if (res.data.code === 9) {
              repeat(fun, interval);
              return;
            } else if (res.data.code === 8 || res.data.code === 10) {
              return Promise.reject(res.msg);
            } else if (res.data.code === 0) {
              if (this.$getCookie('register')) {
                this.registDialogVisible = true;
                this.getValidFunc();
              } else {
                this.$emit("loginSuccess");
              }
              return;
            }
            return Promise.reject(res.data.msg);
          }).catch(err => {
            if (typeof instance === "string") {
              this.$message({
                type: 'error',
                message: err
              });
            } else {
              /**
               * 位置错误 不处理
               */
              //layer.msg("登录失败， 请刷新重试");
            }
          });
        }, interval);
      };

      repeat(login, 1000);
    },
    getPhoneCodeFunc: function (con) {
      if (con === 'login') {
        this.$refs['phoneLoginRef'].validateField('phone', (valid) => {
          if (valid) {
            return false
          }
          let type = {
            type: 'login_sms'
          }
          let params = {
            phone: this.registerDate.country_code === '' || this.registerDate.country_code === undefined
                ? '86-' + this.registerDate.phone : this.registerDate.country_code + '-' + this.registerDate.phone
          }
          this.isRegisteredPhone = true;
          this.clickGetPhoneCodeBtn(type, params)
        })
      } else if (con === 'phonesignup') {
        let type = {
          type: 'signup'
        };
        let params = {
          phone: this.registerDate.country_code === '' || this.registerDate.country_code === undefined
              ? '86-' + this.registerDate.phone : this.registerDate.country_code + '-' + this.registerDate.phone
        };
        this.clickGetPhoneCodeBtn(type, params);
        this.isRegisteredPhone = false;
      } else {
        this.$refs['userInfor'].validateField('phone', (valid) => {
          if (valid) {
            return false
          }
          let type = {
            type: 'signup'
          }
          let params = {
            phone: this.userInfor.country_code === '' || this.userInfor.country_code === undefined
                ? '86-' + this.userInfor.phone : this.userInfor.country_code + '-' + this.userInfor.phone
          }
          this.clickGetPhoneCodeBtn(type, params)
        })
      }
    },
    /**
     * 重置发送验证码的所有参数
     */
    resetCodeButton: function () {
      this.codeButton = {
        isDisabled: false,
        name: '发送验证码',
        time: 60
      };

      window.clearInterval(this.timer);
    },
    clickGetPhoneCodeBtn: function (type, params) {
      this.codeButton.isDisabled = true;
      this.isRegisteredPhone = true;
      getPhoneCode(type, params).then(res => {
        if (res.data.code === 0) {
          this.timer = window.setInterval(() => {
            this.codeButton.name = '(' + this.codeButton.time + '秒)后重发';
            --this.codeButton.time;
            if (this.codeButton.time < 0) {
              this.resetCodeButton();
            }
          }, 1000);
        } else if (res.data.code === 5) {
          this.$alert(res.data.msg, '提示', {
            confirmButtonText: '确定',
            // eslint-disable-next-line no-unused-vars
            callback: (_) => {
              this.timer = window.setInterval(() => {
                this.codeButton.name = '(' + this.codeButton.time + '秒)后重发';
                --this.codeButton.time;
                if (this.codeButton.time < 0) {
                  this.resetCodeButton();
                }
              }, 1000);
            },
          });
        } else if (res.data.code === 2701 && res.data.msg === '手机号不存在,请先注册!') {
          this.isRegisteredPhone = false;
          this.getValidFunc();
          this.getPhoneCodeFunc('phonesignup');
        } else {
          this.$message({
            type: 'error',
            message: res.data.msg
          });
          this.resetCodeButton();
        }
        // eslint-disable-next-line no-unused-vars
      }).catch(res => {
        this.$message({
          type: 'error',
          message: '发送手机验证码失败，请稍后再试'
        });
        this.resetCodeButton();
      });
    },
    checkInvitedCode: function (callback) {
      let params = {
        type: 'invite_code',
        content: this.userInfor.invite_code
      };

      if (this.registDialogVisible) {
        params.content = this.userInfor.invite_code
      } else {
        params.content = this.registerDate.invite_code;
      }

      checkValidCode(params).then(res => {
        if (res.data.code === 0) {
          // this.ifNeedVisiteCode = false;
          callback();
        } else {
          // this.ifNeedVisiteCode = true;
          callback(new Error('输入的邀请码不正确或者无效，请重新输入'));
        }
        // eslint-disable-next-line no-unused-vars
      }).catch(res => {
        // this.ifNeedVisiteCode = true;
        callback(new Error('输入的邀请码不正确或者无效，请重新输入'));
      })
    },
    phoneCodeCheckFunc: function (callback) {
      let params = {
        type: 'signup'
      }
      if (this.registDialogVisible) {
        params.content = this.userInfor.verification
      } else {
        params.content = this.registerDate.phonecode
      }
      checkPhoneCode(params).then(res => {
        if (res.data.code === 0) {
          this.checkCodeOk = true
          callback()
        } else {
          this.checkCodeOk = false
          callback(new Error('输入的验证码不正确'))
        }
        // eslint-disable-next-line no-unused-vars
      }).catch(res => {
        this.checkCodeOk = false
        callback(new Error('验证码验证失败，请重新验证'))
      })
    },
    checkEmailFunc: function (callback) {
      if (this.userInfor.email) {
        let type = {
          type: 'email'
        }
        let params = {
          email: this.userInfor.email
        }
        getPhoneCode(type, params).then(res => {
          if (res.data.code === 0) {
            callback()
          } else {
            callback(new Error('邮箱验证失败，请检查邮箱格式是否正确'))
          }
          // eslint-disable-next-line no-unused-vars
        }).catch(res => {
          callback(new Error('邮箱验证失败，请检查邮箱格式是否正确'))
        })
      }
    },
    /**
     * 提交登录信息， 手机号验证码登录
     */
    loginSubmit: function () {
      if (this.headerTabIndex === 0 && this.footerTabIndex === 1) {
        this.emailLoginSubmit();
      } else {
        this.phoneLoginSubmit();
      }
    },
    /**
     * 账号密码登录
     */
    emailLoginSubmit: function () {
      this.$refs['emailLoginRef'].validate((valid) => {
        if (!valid) {
          return false
        }
        // 登录密码需要加密传输
        let publicKey = 'MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQCX4kRYjU6XclWT4mwxgQ55JerRar8CPEHp4uZRITqZuk8p0z5UTL02muequw2dgvgNlJkE8C7eyXf1S8TH+MvbVkHnCoKKigAPb08PtqcvVIqnZn+kNDpiQZapkpHrEXeR+rWfj3etTwMHYbV+zTaKOdxa7fm3oO4CWbgmfkSC8wIDAQAB'
        let encryptor = new JSEncrypt()
        encryptor.setPublicKey(publicKey)
        let params = {
          login_by: 'account',
          account: this.registerDate.email,
          pwd: encryptor.encrypt(this.registerDate.password)
        }
        loginByAccount(params).then(res => {
          if (res.data.code === 0) {
            if (this.$getCookie('register')) {
              this.registDialogVisible = true
              this.getValidFunc();
            } else {
              this.$emit("loginSuccess");
            }
          } else {
            this.$message({
              type: 'error',
              message: res.data.msg
            })
          }
        }).catch(res => {
          this.$message({
            type: 'error',
            message: res.msg
          })
        })
      })
    },
    /**
     * 手机验证码登录
     */
    phoneLoginSubmit: function () {
      if (this.registerDate.phone === '' || this.registerDate.phonecode === '') {
        this.$refs['phoneLoginRef'].validateField('phone');
        this.$refs['phoneLoginRef'].validateField('phonecode');
      } else {
        let params = {
          login_by: 'sms',
          phone: this.registerDate.country_code === '' || this.registerDate.country_code === undefined
              ? '86-' + this.registerDate.phone : this.registerDate.country_code + '-' + this.registerDate.phone,
          sms_code: parseInt(this.registerDate.phonecode)
        };
        loginByPhone(params).then(res => {
          if (res.data.code === 0) {
            this.$emit("loginSuccess");
          } else {
            this.$message({
              type: 'error',
              message: res.data.msg
            });
          }
        }).catch(err => {
          console.error(err);
          this.$message({
            type: 'error',
            message: '登录失败'
          });
        });
      }
    },
    getValidFunc: function () {
      let params = {
        type: 'invite_code',
        content: 'SYS-OPN00000000'
      }

      getValid(params).then(res => {
        if (res.data.code === 0) {
          this.validCodeControl = true
        } else {
          this.validCodeControl = false
        }
      })
    },
    /**
     * 提交注册信息
     * @param con
     */
    registerSubmit: function (con) {
      this.isLoginRequestNow = true;
      if (con === 'phoneregist') {
        this.phoneRegisterSubmit();
      } else {
        this.wxRegisterSubmit();
      }
    },
    phoneRegisterSubmit: function () {
      this.$refs['phoneLoginRef'].validate((valid) => {
        if (!valid) {
          return false
        }
        if (!this.checkCodeOk) {
          let params = {
            type: 'signup',
            content: this.registerDate.phonecode
          };
          checkPhoneCode(params).then(res => {
            if (res.data.code === 0) {
              this.checkCodeOk = true

              let pa = Object.assign({}, this.registerDate);
              pa.interest = ['其他'];
              pa.method = 'phone';
              this.isRegisteredPhone = true;

              this.userSystemLogin(pa, this.resetRegisterDisable, this.loginError);
            } else {
              this.checkCodeOk = false

              this.$message({
                type: 'error',
                message: '输入的验证码不正确'
              });
              this.resetRegisterDisable();
            }
            // eslint-disable-next-line no-unused-vars
          }).catch(res => {
            this.checkCodeOk = false

            this.$message({
              type: 'error',
              message: '验证码验证失败，请重新验证'
            });
            this.resetRegisterDisable();
          });
        } else {
          let pa = Object.assign({}, this.registerDate);
          pa.interest = ['其他'];
          pa.method = 'phone';

          this.userSystemLogin(pa, this.resetRegisterDisable, this.loginError);
        }
      });
    },
    wxRegisterSubmit: function () {
      this.$refs['userInfor'].validate((valid) => {
        if (!valid) {
          return false;
        }
        let params = Object.assign({}, this.userInfor)
        params.interest = ['其他']

        this.userSystemLogin(params, this.resetRegisterDisable, this.loginError);
      });
    },
    resetRegisterDisable: function(){
      this.isLoginRequestNow=false
    },
    loginError: function (resData) {
      this.isLoginRequestNow=false
      if (resData && resData.msg) {
        this.$message({
          type: 'error',
          message: resData.msg,
          duration: 5000
        })
      }
    },
    userSystemLogin: function (userInfo, callback, errorFunc) {
      // let s = this.$getCookie('s');
      requestLogin(this.source, userInfo,  (res) => {
        if (res.user && res.user.code === 0) {
          if(callback){
            callback()
          }
          this.$emit("signupSuccess");
        } else {
          if (errorFunc) {
            try {
              errorFunc(res.user)
            } catch (e) {
              console.error(e)
            }
          }
        }
      });
    },
    headerTabChange: function (cur) {
      this.headerTabIndex = cur;
    },
    footerTabChange: function (cur) {
      this.footerTabIndex = cur;
    },
  }
}
</script>

<style lang="scss">
.login-container__local {
  background: #fff;
  width: 350px;
  margin: auto;
  .login-tab-wrap {
    padding: 20px 0px 0px;
    text-align: center;
    li {
      font-size: 17px;
      cursor: pointer;
      line-height: 60px;
      display: inline-block;
      margin: 0 15px;
    }
    p {
      font-size: 17px;
      cursor: default;
      padding: 0;
      line-height: 60px;
    }
  }
  .tabIsActive {
    color: #3759CB;
    font-weight: 500;
  }
  .login-content-form {
    padding: 30px 15px 0;
    .register-phone-wrap__local {
      position: relative;
      .el-input-group__prepend {
        width: 50px;
        padding: 0;
        border: 0;
        position: absolute;
        background: transparent;
        top: 50%;
        transform: translateY(-50%);
        .el-input__inner {
          text-align: center;
          border: 0;
          border-radius: 0;
          position: relative;
          height: 12px;
          line-height: 12px;
          vertical-align: middle;
          margin-left: 1px;
          padding: 10px 0;
          border-right: 1px solid #dcdfe6;
        }
      }
      .el-input__inner {
        padding-left: 60px;
        border-radius: 4px;
      }
    }
    .phonecode-input-wrap {
      .el-button--default {
        padding: 7px 15px;
        font-size: 13px;
      }
    }
  }
  .login-body {
    padding: 15px 0;
    color: #a1a1a1;
    font-size: 14px;
    text-align: center;
    #wechatcode {
      position: relative;
      width: 220px;
      height: 220px;
      margin: 0 auto;
    }
    .wxlogin-failed-wrap {
      height: 220px;
      span {
        display: block;
        height: 30px;
        line-height: 30px;
        font-size: 16px;
      }
    }
  }
  span{
    p {
      font-size: 17px;
      cursor: default;
      padding: 0;
      line-height: 60px;
    }
  }
}
.regist-wrap {
  .el-dialog {
    position: absolute;
    margin: 0 auto;
    margin-top: 0!important;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
  }
  .el-dialog__header {
    padding: 35px 15px 20px!important;
    border-bottom: 1px solid #ddd!important;
  }
  .el-dialog__title {
    line-height: 24px;
    font-size: 20px;
    color: #3759CB!important;
    font-weight: 500;
  }
  .el-dialog__footer {
    padding: 10px 20px 20px;
    text-align: right;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    overflow: hidden;
    border: 1px solid #ddd;
    margin-top: -10px;
    .registdialog-footer {
      width: 100%;
      overflow: hidden;
      text-align: center;
      .el-button--primary.is-disabled, .el-button--primary.is-disabled:active,
      .el-button--primary.is-disabled:focus, .el-button--primary.is-disabled:hover {
        color: #fff!important;
        background-color: #c8c9cc!important;
        border-color: #c8c9cc!important;
      }
    }
    p {
      font-size: 13px;
      color: #606266;
      margin: 15px 0;
      a {
        color: #3759CB;
        margin-left: 8px;
      }
    }
  }
  .btn-all {
    background-color: #3399FF!important;
    border-color: #3399FF!important;
    padding: 10px 15px!important;
    width: 55%;
  }
}
</style>
